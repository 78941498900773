import { createContext, useContext, useEffect, useState } from "react";
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { env } from "../env/env";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [resetOrderState, setResetOrderState] = useState(false);
  const [save, setSave] = useState(0);
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [orderStoreName, setOrderStoreName] = useState("");
  const [scanDisabled, setScanDisabled] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [receiptData, setReceiptData] = useState(null);
  const [overallData, setOverallData] = useState(null);
  const [receiptImage, setReceiptImage] = useState({ img: null, currency: "" });
  const [distanceStoreAddress, setDistanceStoreAddress] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [country, setCountry] = useState("");
  const [coords, setCoords] = useState({
    lat: localStorage.getItem("latitude") || null,
    lng: localStorage.getItem("longitude") || null,
  });
  const [showModal, setShowModal] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [receiptLink, setReceiptLink] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [couponsLoaded, setCouponsLoaded] = useState(false);

  const { i18n } = useTranslation();
  const [language, setLanguageInternal] = useState(i18n.language);

  const setLanguage = (language) => {
    // The useTranslation hook stores language state already,
    // but the point of adding setters and getters here is because
    // i18n.changeLanguage() does not force a render while the React context
    // hook does.
    // So stale data is displayed in some cases (mainly in Profile.jsx) without
    // adding getters/setters here.
    i18n.changeLanguage(language);
    setLanguageInternal(language);
  };

  const sendVerificationEmail = async (email, id) => {
    let userID = id;
    const loginEmail = email.toLowerCase();
    if (!id) {
      const q = query(
        collection(db, "users"),
        where("email", "==", loginEmail),
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs[0].data();
      userID = data.id;
    }
    await axios.post(
      "https://relaxed-pothos-983d23.netlify.app/api/verify-customer",
      {
        email: loginEmail,
        id: userID,
      },
    );
  };

  const reloadUser = async () => {
    if (user) {
      const q = query(collection(db, "users"), where("id", "==", user.id));
      const queryResult = await getDocs(q);
      if (queryResult.docs.length > 0) {
        const userData = queryResult.docs[0].data();
        setUser(userData);
      }
    }
  };

  const verifyEmail = async (email, id) => {
    const loginEmail = email.toLowerCase();

    const q = query(collection(db, "users"), where("email", "==", loginEmail));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      throw new Error("unknown-account");
    }
    const userSnapshot = querySnapshot.docs[0];

    let userData = userSnapshot.data();
    userData = { ...userData, verified: true };

    if (userData.id !== id) {
      throw new Error("Sorry. Invalid verification link.");
    }

    const docRef = doc(db, "users", id);
    await setDoc(docRef, userData);
    return true;
  };

  const registerUser = async (email, password, name, referralCode) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      const userID = user.uid;
      const docRef = doc(db, "users", userID);

      await sendEmailVerification(auth.currentUser);

      const payload = {
        address: [],
        email: email,
        name: name,
        id: userID,
        uid: userID
      };
      await setDoc(docRef, payload);

      if (referralCode) {
        const codesRef = collection(db, "referralCodes");
        const codeDocs = await getDocs(
          query(codesRef, where("code", "==", referralCode))
        );
        if (codeDocs.docs.length === 0) {
          return userID;
        }
        const referrerID = codeDocs.docs[0].id;

        const referralDocRef = doc(db, "referrals", userID);
        await setDoc(referralDocRef, {
          referrer: referrerID,
          hasMadePurchase: false,
          isDiscountActiveForReferrer: false,
          isDiscountActiveForReferred: false
        });
      }

      return userID;
    } catch (error) {
      console.error("Error registering user:", error);
      throw error; // Rethrow the error to handle it in the calling code
    }
  };

  const deleteDocument = async (collectionName, documentId) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      await deleteDoc(docRef);
      await reloadUser();
      console.log("Document deleted successfully.");
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const deleteAddress = async (addressData, addressId) => {
    const updatedAddress = addressData.filter(
      (_, index) => index !== addressId,
    );
    try {
      const userDocRef = doc(db, "users", user.id); // Assuming 'users' is the collection name
      await updateDoc(userDocRef, { address: updatedAddress });
      console.log("Address deleted successfully!");
      // Optionally, you can reload the data or update the state here
    } catch (error) {
      console.error("Error deleting address:", error);
      // Handle the error as needed
    }
  };

  const sendPasswordEmail = async (email) => {
    sendPasswordResetEmail(email);
  };

  const logout = async () => {
    await signOut(auth);
    setUser({});
    localStorage.removeItem("overallOrder");
    localStorage.removeItem("order");
  };

  const loginUser = async (email, password) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (result.user.emailVerified) {
      const q = query(
        collection(db, "users"),
        where("id", "==", result.user.uid),
      );
      const querySnapshot = await getDocs(q);
      const userData = querySnapshot.docs[0].data();
      setUser(userData);
    } else {
      await logout();
      throw new Error("unverified");
    }
  };

  const getCouponLimit = (coupons, couponCode) => {
    // Find the coupon object that matches the provided coupon code
    const matchedCoupon = coupons.find((coupon) => coupon.name === couponCode);

    if (matchedCoupon) {
      return matchedCoupon.limit;
    } else {
      return;
    }
  };

  const saveAppliedCoupon = async (
    userId,
    currentUser,
    couponCode,
    coupons,
  ) => {
    // Only save a coupon code if it exists and is not blank.
    if (couponCode && couponCode !== "") {
      try {
        const userRef = doc(db, "users", userId);

        if (currentUser) {
          const appliedCoupons = currentUser.appliedCoupons || [];
          const matchedCouponIndex = appliedCoupons.findIndex(
            (item) => item.coupon === couponCode,
          );

          if (matchedCouponIndex !== -1) {
            const appliedCount =
              appliedCoupons[matchedCouponIndex].appliedCount;
            const limit = getCouponLimit(coupons, couponCode); // Replace with a function to get the limit for the applied coupon

            // Check if the applied count is less than the limit or if there is no limit
            if (appliedCount < limit || limit === -100) {
              const updatedCount = appliedCount + 1;
              appliedCoupons[matchedCouponIndex].appliedCount = updatedCount;
              await updateDoc(userRef, { appliedCoupons });
            } else {
              throw new Error("Coupon limit exceeded");
            }
          } else {
            const newCoupon = { coupon: couponCode, appliedCount: 1 };
            await updateDoc(userRef, { appliedCoupons: arrayUnion(newCoupon) });
          }
        }
      } catch (error) {
        console.error("Error updating coupon data:", error);
      }
    }
  };

  const markReferrerPurchase = async (userID) => {
    const docRef = doc(db, "referrals", userID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().hasMadePurchase) {
        await updateDoc(docRef, {
          isDiscountActiveForReferred: false
        });
      } else {
        await updateDoc(docRef, {
          hasMadePurchase: true,
          isDiscountActiveForReferrer: true,
          isDiscountActiveForReferred: true
        });

        // Send emails notifying customers of free delivery

        const referrerID = docSnap.data().referrer;

        const referrerDocRef = doc(db, "users", referrerID);
        const friendDocRef = doc(db, "users", userID);

        const [referrerData, friendData] = await Promise.all(
          [referrerDocRef, friendDocRef].map(ref => getDoc(ref).then(snap => snap.data()))
        );

        const requestBody = {
          referrerName: referrerData.name,
          friendName: friendData.name,
        };

        await fetch(
          "https://backend.wecarrybags.co.uk/api/v1/refer/email-fd-referrer",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...requestBody, email: referrerData.email }),
          },
        );
        await fetch(
          "https://backend.wecarrybags.co.uk/api/v1/refer/email-fd-referred",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...requestBody, email: friendData.email }),
          },
        );
      }
    }

    const querySnapshot = await getDocs(
      query(
        collection(db, "referrals"),
        where("referrer", "==", userID),
        where("isDiscountActiveForReferrer", "==", true)
      ),
    );
    const docSnap2 = querySnapshot.docs[0];
    if (docSnap2 !== undefined && docSnap2.exists()) {
      const docRef2 = doc(db, "referrals", docSnap2.id);
      await updateDoc(docRef2, {
        isDiscountActiveForReferrer: false
      });
    }
  }

  const updateUserAddress = async (addressIndex, wholeData, addressData) => {
    const updatedAddress = wholeData.filter(
      (_, index) => index !== addressIndex,
    );
    updatedAddress.push(addressData);
    try {
      const userDocRef = doc(db, "users", user.id);
      await updateDoc(userDocRef, { address: updatedAddress });
      await reloadUser();
      console.log("Address deleted successfully!");
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  function distanceBetween(coord1, coord2) {
    const [lat1, lon1] = coord1;
    const [lat2, lon2] = coord2;

    const R = 6371;

    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance.toFixed(2);
  }

  useEffect(() => {
    const loadOrderData = async () => {
      const q = query(collection(db, "orders"), where("user", "==", user.id));
      const querySnapshot = await getDocs(q);
      const orderArray = [];
      querySnapshot.forEach((doc) =>
        orderArray.push({ id: doc.id, ...doc.data() }),
      );
      setOrderData(orderArray);
    };

    if (user && user.id) {
      loadOrderData();
    }
  }, [user]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const q = query(collection(db, "users"), where("id", "==", user.uid));
        const queryResult = await getDocs(q);
        if (queryResult.docs.length > 0) {
          const userData = queryResult.docs[0].data();
          setUser(userData);
        }
      } else {
        // Routes the user is allowed to be in when not logged in.
        const authorisedRoutes = [
          "/", //login
          "/register",
          "/data-contact",
          "/faq",
          "/terms-and-conditions",
          "/privacy-policy",
          "/forgot-password",
        ];

        // More routes the user is allowed to be in when not logged in,
        // but these routes take query parameters
        // so detection logic needs to be different.
        const partialAuthorisedRoutes = [
          "/reset",
          "/waiting-verification",
          "/verify",
        ];

        const currentPath = location.pathname;
        const isInAuthorisedRoutes = authorisedRoutes.some(
          (routePath) => routePath === currentPath,
        );
        const isInPartialAuthorisedRoutes = partialAuthorisedRoutes.some(
          (routePath) => currentPath.includes(routePath),
        );
        const isAuthosied = isInAuthorisedRoutes || isInPartialAuthorisedRoutes;
        if (!isAuthosied) {
          navigate("/");
        }
      }
    });

    return () => unsubscribe();
  }, [location, navigate]);

  useEffect(() => {
    const fetchMyData = () => {
      if (user && user.id) {
        try {
          onSnapshot(
            query(collection(db, "orders"), where("user", "==", user.id)),
            (docs) => {
              const order = docs.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setOrderData(order);
            },
            (error) => {
              console.error("Error fetching orders:", error);
            },
          );
        } catch (error) {
          console.error("Error in fetching orders:", error);
        }
      }
    };

    if (user) {
      fetchMyData();
    }
  }, [user]);

  const unPaidOrder = orderData.find((order) => !order.isPaid);
  useEffect(() => {
    if (unPaidOrder) {
      setScanDisabled(true);
      setCurrentOrderId(unPaidOrder.id);
    }
  }, [orderData, resetOrderState, unPaidOrder]);

  const sendReceiptEmail = async (receipt) => {
    console.log("RECEIPT:", receipt);
    try {
      // Send receipt data to our own Netlify function so it's emailed to customer
      await axios.post(
        "https://backend.wecarrybags.co.uk/api/v1/customer/email-receipt",
        receipt,
      );
    } catch (err) {
      console.log("emailErr", err);
    }
  };

  const loadReceipt = async (receiptData) => {
    try {
      // Send receipt data to an invoice generator site as well
      const response = await fetch(
        "https://invoice-generator-pinb.onrender.com/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(receiptData),
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        setReceiptLink(responseData.url);
        return responseData.url;
      } else {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const genReceipt = (
    customerName,
    orderID,
    serviceType,
    servicePrice,
    discountPrice,
    storeName,
    currency,
    address,
  ) => {
    return {
      from: "CarryBags Ltd.",
      logo: "https://i.imgur.com/smmVOeC.png",
      balance_title: "Total Paid",
      notes_title: "Store",
      to_title: "Customer",
      fields: {
        discounts: true,
      },
      to: `${customerName}`,
      number: `${orderID}`,
      discounts: discountPrice,
      ship_to: `${customerName}\n${address}`,
      items: [
        {
          name: `${serviceType}`,
          quantity: 1,
          unit_cost: servicePrice,
        },
      ],
      currency: `${currency}`,
      notes: `${storeName}\n\n\n\nThank you!`,
    };
  };

  const [currentBags, setCurrentBags] = useState([]);
  const [currentStore, setCurrentStore] = useState(null);
  const [, setUsedBagID] = useState("");

  const [successData, setSuccessData] = useState(null);

  const addBag = async (
    bagString,
    notifyAdd,
    notifyAlreadyScanned,
    notifyInvalid,
    notifyBagUsed,
    notifyAddedFromAnotherStore,
    goNext,
  ) => {
    try {
      const newBag = JSON.parse(bagString);

      // Check parsed JSON fits schema.
      if (newBag.bagID && newBag.storeID) {
        const currentBagID = newBag.bagID;
        const bagRef = doc(db, "bags", currentBagID);
        const bagSnap = await getDoc(bagRef);
        if (bagSnap.exists()) {
          const bagData = bagSnap.data();
          // If this is reached, then the bag was already used to pay for a previous order.
          if (bagData.orderID) {
            setUsedBagID((oldBagID) => {
              if (oldBagID === currentBagID) {
                return currentBagID;
              } else {
                notifyBagUsed();
                return currentBagID;
              }
            });
            return;
          }
        }
        const currentStoreID = newBag.storeID;
        setCurrentBags((bags) => {
          // Check if bag is already scanned.
          const isAlreadyScanned = bags.some(
            (bag) => currentBagID === bag.bagID,
          );

          // If bag was scanned before, return old bags.
          if (isAlreadyScanned) {
            notifyAlreadyScanned();
            goNext(bags);
            return bags;
          }
          // If newly scanned bag has different store ID,
          // remove bags from previous store and add only this bag.
          else if (bags.length > 0 && bags[0].storeID !== currentStoreID) {
            notifyAddedFromAnotherStore();
            const result = [newBag];
            goNext(result);
            return result;
          }

          // Create new array containing new bags and set its state.
          notifyAdd();
          const result = [...bags, newBag];
          goNext(result);
          return result;
        });
      } else {
        notifyInvalid();
      }
    } catch (err) {
      console.log(err);
      notifyInvalid();
    }
  };

  const loadCoupons = async () => {
    const querySnapshot = await getDocs(collection(db, "Coupons"));
    const coupons = querySnapshot.docs.map((doc) => doc.data());
    setCoupons(coupons);
    setCouponsLoaded(true);
  };

  useEffect(() => {
    if (!couponsLoaded) {
      loadCoupons();
    }
  });

  const loadStoreWith = async (currentBags) => {
    // Get store ID from bags array,
    // assuming no logistics errors occurred
    // and that all bags were sent to correct store.
    const storeID = currentBags[0].storeID;

    // Load store document from Firebase.
    
    const q = query(collection(db, "stores"), where("uid", "==", storeID));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const storeData = querySnapshot.docs[0].data();
      setCurrentStore(storeData);
      return storeData;
    }
    
    const docRef = doc(db, "stores", storeID);
    const docSnap = await getDoc(docRef);
    if (docSnap) {
      const storeData = docSnap.data();
      setCurrentStore(storeData);
      return storeData;
    }
    
    throw new Error(`could-not-load-store: ${storeID}`);
  };

  const saveScanPageWith = async (currentBags) => {
    const store = await loadStoreWith(currentBags);
    await loadCoupons();
    const paymentStage = {
      currentBags: currentBags,
      store: store,
    };
    localStorage.setItem("order", JSON.stringify(paymentStage));
  };

  const sendOrderEmailToAdmin = async (orderID) => {
    try {
      await axios.post(
        "https://backend.wecarrybags.co.uk/api/v1/admin/email-order",
        {
          orderID: orderID,
          production: env.production,
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const deleteOrderData = () => {
    setCurrentBags([]);
    setCurrentStore(null);
    setReceiptData(null);
    setOverallData(null);
    localStorage.removeItem("order");
    localStorage.removeItem("overallData");
  };

  const setBagsAsUsed = async (currentBags, orderID) => {
    const promiseArray = currentBags.map((bag) => {
      const bagRef = doc(db, "bags", bag.bagID);
      return setDoc(bagRef, {
        ...bag,
        orderID: orderID,
      });
    });
    await Promise.all(promiseArray);
  };

  const getStoreById = async (storeId) => {
    const myDocRef = doc(db, "stores", storeId);
    const myDocSnap = await getDoc(myDocRef);
    if (myDocSnap.exists()) {
      return myDocSnap.data();
    } else {
      const q = query(collection(db, "stores"), where("uid", "==", storeId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        return querySnapshot.docs[0].data();
      }
    }
  };

  // Side-effecting function that sets bag and store data
  // if localStorage has this data, returning true if the data is there.
  // If the data is not there, intialises bag and store data to the empty state,
  // and returns false.
  const hasOrderInLocalStorage = () => {
    // Check if there is order data in localStorage
    const orderString = localStorage.getItem("order");
    if (orderString) {
      try {
        const order = JSON.parse(orderString);
        const hasOrderInLocalStorage =
          order && order.currentBags && order.currentBags.length && order.store;
        if (hasOrderInLocalStorage) {
          setCurrentBags(order.currentBags);
          setCurrentStore(order.store);
          return true;
        } else {
          setCurrentBags([]);
          setCurrentStore(null);
          return false;
        }
      } catch (err) {
        console.log("orderStr", err);
        setCurrentBags([]);
        setCurrentStore(null);
        return false;
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        registerUser,
        user,
        logout,
        loginUser,
        selectedCategories,
        setSelectedCategories,
        sendPasswordEmail,
        save,
        setSave,
        coords,
        setCoords,
        country,
        setCountry,
        distanceStoreAddress,
        setDistanceStoreAddress,
        distanceBetween,
        clientSecret,
        setClientSecret,
        overallData,
        setOverallData,
        saveAppliedCoupon,
        markReferrerPurchase,
        scanDisabled,
        setScanDisabled,
        currentOrderId,
        setCurrentOrderId,
        receiptData,
        setReceiptData,
        deleteDocument,
        showModal,
        setShowModal,
        deleteAddress,
        editAddress,
        setEditAddress,
        updateUserAddress,
        orderStoreName,
        setOrderStoreName,
        receiptImage,
        setReceiptImage,
        orderData,
        setOrderData,
        setResetOrderState,
        resetOrderState,
        language,
        setLanguage,
        stripePromise,
        setStripePromise,
        sendReceiptEmail,
        receiptLink,
        setReceiptLink,
        loadReceipt,
        genReceipt,
        sendVerificationEmail,
        verifyEmail,
        reloadUser,
        currentBags,
        setCurrentBags,
        addBag,
        currentStore,
        setCurrentStore,
        saveScanPageWith,
        coupons,
        sendOrderEmailToAdmin,
        deleteOrderData,
        successData,
        setSuccessData,
        setBagsAsUsed,
        getStoreById,
        hasOrderInLocalStorage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
